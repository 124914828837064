import { Badge } from '@repo/ui/components/Badge.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { Link, href } from 'react-router'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { UserAvatar } from './UserAvatar'

export const UserBadge = ({
  userId,
  displayName,
  avatarUrl,
  to,
  className,
  ...props
}: {
  displayName: string
  userId: string
  avatarUrl: string | null
  to?: string
} & ComponentProps<typeof Badge>) => {
  const user = useAuthenticatedUser()

  const isClickable =
    user.isInternal || (user.role && user.role !== 'restricted')

  return (
    <Link
      to={to || href('/users/:userId', { userId })}
      onClick={(e) => {
        if (!isClickable) {
          e.preventDefault()
        }
      }}
      prefetch={isClickable ? 'intent' : undefined}
    >
      <Badge
        className={cn(
          'gap-1 font-medium',
          isClickable ? 'hover:underline' : 'cursor-default',
          className
        )}
        variant="secondary"
        {...props}
      >
        <UserAvatar src={avatarUrl} className="size-4 max-h-3/4" />
        <span className="max-w-48 truncate">{displayName}</span>
      </Badge>
    </Link>
  )
}
